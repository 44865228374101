import api from '../api'
/**
 * 标签申请-我的待办
 */
export class TodoApi extends api {
  /**
   * 查询流程历史记录
   * @param {*} form
   * @returns
   */
  getHistories(bussinessKey) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/history/${bussinessKey}`,
      method: 'get'
    })
  }

  getTasks(data) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getTasks`,
      method: 'post',
      data:data
    })
  }

  audit(data) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/audit`,
      method: 'post',
      data:data
    })
  }

    /**
   * 暂存
   * @param {*} form
   * @returns
   */
  editTemporaryStorage(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/editTemporaryStorage`,
      method: 'post',
      data: form
    })
  }
  getByKey(key){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getByKey/${key}`,
      method: "get"
    });
  }
  getVariables(taskId){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getVariables/${taskId}`,
      method: "get"
    });
  }
}

export default new TodoApi('labelApply/todo')