<template>
    <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"  v-model:listQuery="listQuery" :index-column="true">
    <template #condition>
      <el-form-item  label="筛选日期：">
        <el-date-picker v-model="date" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"  range-separator="至" :editable="false" :clearable="false"/>
      </el-form-item>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="制单人或送办人" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">查询</el-button>
      </el-form-item>

    </template>
    <template #columns>
      <el-table-column min-width="150px" label="单据类型" align="center">
          <span>标签申请单</span>
      </el-table-column>
      <el-table-column min-width="150px" label="标签申请人" align="center" property="creatorName">
        <template  #default="scope">
          <span>{{scope.row.creatorName}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="送办时间" align="center" property="updateTime">
        <template  #default="scope">
          <span>{{scope.row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="送办人" align="center" property="deliverName">
        <template  #default="scope">
          <span>{{scope.row.deliverName}}</span>
        </template>
      </el-table-column>      
      <el-table-column min-width="150px" align="center" label="申请标签（套）" property="labelCount">
        <template  #default="scope">
          <span>{{scope.row.labelCount}}</span>
        </template>
      </el-table-column>    
      <el-table-column min-width="150px" align="center" label="发布标签（套）" property="releaseCount">
        <template  #default="scope">
          <span>{{scope.row.releaseCount}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" align="center" label="流程状态" property="status">
        <template  #default="scope">
          <el-tag>{{scope.row.status&& scope.row.status.type}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="300px" class-name="fixed-width">
        <template  #default="scope">
          <el-button type="primary" size="mini" @click="handleInfo(scope.row)" v-waves>查看</el-button>
          <el-button type="success" size="mini" @click="handleAudit(scope.row)" v-waves>办理</el-button>
          <el-button type="info" size="mini" @click="flowLog(scope.row)" v-waves>流程记录</el-button>
        </template>
      </el-table-column>
    </template>
    <Workflow :workflowNode="workflowNode" :workflowApi="todoApi" @complete="getList()"></Workflow>
    <el-dialog v-model="logShow" :show-close="true" center title="流程记录" destroy-on-close :append-to-body="true">
      <component :is="component" :sourceApi="todoApi" :formData="formData"></component>
    </el-dialog>
  </list-condition-template>
</template>

<script>
import dayjs from 'dayjs'
import todoApi from '@/apis/apply/todo'
import flowHistoryForm from './flowHistory'
import Workflow from '@/components/workflow'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'

export default {
  data () {
    return {
      todoApi,
      rows: [],
      total: 0,
      listQuery: {
        condition: {
          keyword: undefined,
          startTime:undefined,
          endTime:undefined
        },
        pageInfo:{
          pageIndex:1,
          pageSize:20,
          orders: []
        }
      },
      component:null,
      formData:null,
      logShow:false,
      date:[dayjs().subtract(1, 'month'),dayjs()],
      workflowNode: {
        businessObject: {
        }
      }
    }
  },
  components:{ listConditionTemplate , Workflow, flowHistoryForm},
  computed: {

  },
  watch:{

  },
  mounted () {
    if(this.$route.query.id){
      todoApi.getById(this.$route.query.id).then(resp=>{
        var rowInfo = resp.data
        this.handleAudit(rowInfo)
      })
    }
  },
  methods: {
    /**
     * 搜索条件变更处理
     */
    handleFilter (pageInfo) {
      this.getList(pageInfo)
    },
        /**
     * 加载数据
     */
    getList (pageInfo) {
      this.listQuery.condition.startTime = dayjs(this.date[0]).format('YYYY-MM-DD 00:00:00')
      this.listQuery.condition.endTime = dayjs(this.date[1]).format('YYYY-MM-DD 23:59:59')
      todoApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    /**
     * 分页信息变动事件处理
     * @param  {Object} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      this.getList()
    },    
    handleInfo(row){
      this.$router.push({path:`/business/cooperation/apply/billInfo/${row.id}`, query: { source:'todo'}})
    },
    handleAudit(row){
      this.workflowNode = {
        processInstanceId: row.processInstanceId,
        assignee: this.$store.state.user.account,
        processDefinitionKey: row.processDefinitionKey,
        businessKey: row.id,
        businessObject: row
      }
    },
    flowLog(row){
      this.logShow = true
      this.formData=row
      this.component = flowHistoryForm
    },

  }
}
</script>

<style lang="scss">

</style>
